<template>
    <div class="customized">
        <Header :type="headerType"/> 
        <div class="main">
            <div class="rule">
            <h1>Customized model</h1>
            <p>Can't find the decorations you want ?<br>Or want to show 3D models of your own products ?<br>We provide customized services to create an ideal model for you !</p>
            <ul>
                <li data-num="1">
                    Prepare information and references  needed to make the model
                </li>
                <li data-num="2">
                    Use the contact to discuss the details with us
                </li>
                <li data-num="3">
                    When the model is completed, you can use your customized model in the exhibition editing
                </li>
            </ul>
        </div>
        <div class="contactus">
            <h2>Contact us </h2>
            <ul>
                <li><div class="iconWrap"><img src="@/assets/img/icon/mailIcon.svg" alt=""></div>service@izexpo.com</li>
                <li><div class="iconWrap"><img src="@/assets/img/icon/phoneIcon.svg" alt=""></div>04-26997997</li>
                <li><div class="iconWrap"><img src="@/assets/img/icon/locationIcon.svg" alt=""></div>No. 403, Sec. 2, Shatian Rd., Dadu Dist., Taichung City 432001</li>
                <!-- <li><div class="iconWrap"><img src="@/assets/img/icon/linkIcon.svg" alt=""></div>https://bkhole.com/</li> -->
            </ul>
        </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header'
export default {
    name:'Customized',
    data(){
        return{
            headerType:'normal_c',
        }
    },
    components:{
        Header
    },
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .customized{
        background-color:$lightPurple;
        .main{
            width: 35%;
            margin:  0 auto;
            min-width: 600px;
        }
        .rule{
            padding-top: 40px;
            h1{
                font-size: $font*37;
                font-weight: 500;
                @include for-size(desktop-only) {
                    font-size: 29px;
                }
            }
            p{
                font-size: $font*18;
                line-height: 1.5;
                //margin-bottom: 70px;
                margin-bottom: 9%;
                @include for-size(desktop-only) {
                    font-size: 14px;
                }
            }
            ul{
                text-align: left;
                li{
                    font-size: $font*18;
                    padding-left: $font*44;
                    text-indent: $font*-76;
                    margin-bottom: 4%;
                    @include for-size(desktop-only) {
                        font-size: 14px;
                    }
                    &:before{
                        content: attr(data-num);
                        width: $font*45;
                        padding:$font*11 0;
                        display: inline-block;
                        background-color: #EFEBFD;
                        color:$gray;
                        font-size: $font*20;
                        text-align: center;
                        border-radius: 100%;
                        text-indent: 0px;
                        margin-right: $font*27;
                        @include for-size(desktop-only) {
                            font-size: 14px;
                        }
                    }
              
                }
            }
        }
        .contactus h2{
            font-size: $font*30;
            margin-top: 10%;
            @include for-size(desktop-only) {
                font-size: 25px;
            }
        }
        .contactus ul{
            text-align: left;
            margin-top: 10px;
            li{
                display: flex;
                align-items: center;
                font-size: $font*14;
                margin-bottom: 18px;
                @include for-size(desktop-only) {
                    font-size: 14px;
                }
                .iconWrap{
                    text-align: center;
                    width: 18px;
                    margin-right: 15px;
                }
                img{
                    width:18px;
                    display: inline-block;
                    vertical-align: middle
                }
                &:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4){
                        img{
                            width:15px;
                        }
                }
            }
        }
    }
</style>